import React from "react";
import "../css/ContentPage.css";
import { Helmet } from "react-helmet";
import LoanCalculator from "../components/LoanCalculator";

const InstantLoan = () => {


  const featuresData = [
    {
      id: 1,
      icon: '🔍',
      title: 'Loan Disbursal in Just 10 Minutes',
    },
    {
      id: 2,
      icon: '💰',
      title: 'Zero Hidden Charges',
    },
    {
      id: 3,
      icon: '💻',
      title: '100% Digital Process',
    },
    {
      id: 4,
      icon: '📅',
      title: 'Collateral free loan',
    },
    {
      id: 5,
      icon: '📄',
      title: 'Easy and Transparent Terms & Conditions',
    },
  ];


  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://salaryontime.com/instant-loan" />
        <title>Instant Personal Loan | SalaryOnTime</title>
        <meta
          name="title"
          content="Instant Personal Loan | SalaryOnTime"
        />
        <meta
          name="description"
          content="Instant Personal Loan – Apply for an instant Personal loan online with minimum documentation from SalaryOnTime. Check pre-approved offers, pre-assigned limits for immediate processing and get immediate funds."
        />
        <meta
          name="keywords"
          content="pre-approved personal loan, pre-approved personal loan offer, pre-approved offer, pre-approved loan offer, instant personal loan, instant loan, pre-approved loan limit, pre-approved limit, loan offers, immediate processing, instant loan"
        />
      </Helmet>
      <div className="content_page_wrapper">

        <div className="text_instant_wrapper" style={{ color: "black" }} >
          <div className="text_instant">
            <h1 className="page_title mt30 mb20">An Insightful Overview of Instant Loan</h1>
            <p>
            Before you apply for an<strong style={{ color: "black", fontStyle: "italic" }}> Instant Loan</strong>, you first need to know what <strong style={{ color: "black", fontStyle: "italic" }}>Instant Loan</strong> is. Do you really need it? And, in what circumstances you should ideally opt for this type of loan? Let’s first learn what an Instant Loan is.
            </p>
            <br />
            <p>As the name suggests, it’s a type of loan in which you get access to funds instantly. Unlike banks and other <strong style={{ color: "black", fontStyle: "italic" }}> NBFCs</strong>, SalaryOnTime, an <strong style={{ color: "black", fontStyle: "italic" }}>instant personal loan app in India</strong>, allows borrowers to access funds in just 10 minutes. The highlight of an <strong style={{ color: "black", fontStyle: "italic" }}>Instant Loan </strong>is how quickly the loan amount is disbursed to your bank account after the application process is accomplished. Prompt disbursement makes a difference, allowing you to fulfil financial obligations on time.</p>
            <br/>
            <p>The question is how does Instant Loan allow a borrower to access funds instantly? Well, an Instant Loan doesn’t involve LAP (loan against collateral). Therefore, no time is consumed for collateral verification or extensive paperwork. Secondly, at SOT, right from application to disbursal, the entire process is 100% digital and paperless, saving ample time and effort. Thirdly, our proficient team is highly trained and experienced to minimize the time taken in loan processing. With all these phenomenal features, we significantly reduce the time and make disbursement swift and effective.</p>
            <br/>
          </div> 
          <div className="calculator_banner">
            <LoanCalculator />
          </div>
        </div>
        <div className="features-container">
      <h2 className="features-title">
        Instant Personal Loan Online <span className="highlight">Features</span>
      </h2>
      <div className="features-list">
        {featuresData.map((feature) => (
          <div key={feature.id} className="feature-item">
            <div className="feature-icon">{feature.icon}</div>
            <div className="feature-title">{feature.title}</div>
          </div>
        ))}
      </div>
    </div>
        <div className="loan">
        <h1>Eligibility Criteria and Documentation</h1>
    <div className="loan-info-container">
      {/* Personal Loan Eligibility */}
      <div className="loan-card">
        <div className="card-header eligibility-header">
          Personal Loan <span className="bold-text">Eligibility</span>
        </div>
        <div className="card-content">
          <ul>
            <li>Any salaried professional with a minimum of 30,000 in-hand salary can secure an instant loan from SalaryOnTime, India's <strong style={{ color: "black", fontStyle: "italic" }}>fastest instant loan app</strong>.</li>
            <li>Age is not a bar. However, the person must be a working professional. </li>
            <li>Lastly, he/she must be receiving the salary in his/her bank account. People with salary credited in cash shall not be entertained.</li>
          </ul>
        </div>
      </div>

      {/* Documents Required */}
      <div className="loan-card">
        <div className="card-header documents-header">
          <span className="bold-text">Documents</span> Required to Secure an Instant Loan

        </div>
        <div className="card-content">
          <p>As mentioned, we ask for minimal documents or you can say basic documents to secure a loan from us. Below is the list of documents that’ll help you secure whether an instant loan, short term loan or emergency loan at lightning-fast speed.</p>
          <ul>
            <li>Aadhar Card</li>
            <li>PAN Card</li>
            <li>Bank Statment (Till date today)</li>
            <li>A Selfie Photo</li>
            <li>Salary Slip (last 3 months)</li>
            <li>Address Proof, such as Gas/Electricity/Water/Wifi Bill</li>
          </ul>
        </div>
      </div>
    </div>
    </div>
    <div className="content_instant_wrapper">
        <div className="text_content_wrapper">
          <div className="text_content" style={{ width: "95%" }}>
            <h1 className="page_title mt30 mb50">Frequently asked questions</h1>
            <div className="content_row">
              <div className="content_item">
                <h3 className="mb10">What is an Instant Loan ?</h3>
                <p>
                  In such a loan, a borrower gets access to funds promptly, enabling him/her to cover the high-priority expenses on time. In simple terms, an instant loan is a short-term loan that is processed and disbursed quickly as it does not require any collateral or extensive paperwork.
                </p>
              </div>
              <div className="content_item">
                <h3>How do I apply for an Instant Loan?</h3>
                <p>
                The application procedure is simply effortless. Let’s see how effortlessly you can apply.
                </p>
                <ul style={{color:"black"}}>
                  <li> Firstly, go to our website <a style={{color:"blue"}} href="https://salaryontime.com/"  target="_blank">https://salaryontime.com/</a> and click on the <strong style={{ color: "black", fontStyle: "italic" }}>“Apply Now”</strong> button. </li>
                  <li> Then, register your mobile number by entering the OTP received on your mobile number.</li>
                  <li> Fill in the basic details form.</li>
                  <li> Lastly, upload the required documents to complete your loan application journey. Once the journey is completed, our team will get in touch with you shortly. Alternatively, you can also check the status of your application on the app’s dashboard.</li>
                </ul>
              </div>
            </div>
            <div className="content_row">
              <div className="content_item">
                <h3 className="mb10">How long does it take to process an instant loan?</h3>
                <p>
                SalaryOnTime generally takes around 10 minutes in loan processing provided a borrower uploads all the required documents on time.
                </p>
              </div>
              <div className="content_item">
                <h3> Can I get a 50000 loan from SalaryOnTime?</h3>
                <p>
                Depending on your eligibility, you can get loans up to 1 lakh from SalaryOnTime. 
                </p>
              </div>
            </div>

            <div className="content_row">
              <div className="content_item">
                <h3 className="mb10">
                Can I apply for an Instant Loan with a low credit score?
                </h3>
                <p>
                Yes! Why not, SalaryOnTime provides instant personal loans to people with even a low credit score.
                </p>
              </div>
              <div className="content_item">
                <h3>Can I get a 50000 loan from SalaryOnTime?</h3>
                <p>
                Depending on your eligibility, you can get loans up to 1 lakh from SalaryOnTime.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default InstantLoan;
