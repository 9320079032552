import React from "react";
import { Route, Routes } from "react-router-dom";
import LoanLayout from "./LoanLayout";
import Layout from "./Layout";
import ScrollToTop from "./components/ScrollTop";
import ApplyForLoan from "./pages/ApplyForLoan";
import RepayLoan from "./pages/RepayLoan";
import DashBoard from "./pages/Dashboard/DashBoard";
import PanDetails from "./pages/Dashboard/PanDetails/PanDetails";
import CapturAddress from "./pages/Dashboard/CapturAddress/CapturAddress";
import CapturPersonalInformation from "./pages/Dashboard/CapturPersonalInformation/CapturPersonalInformation";
import CapturIncomeDetails from "./pages/Dashboard/CapturIncomeDetails/CapturIncomeDetails";
import UploadPicture from "./pages/Dashboard/UploadPicture/UploadPicture";
import ProfilePage from "./pages/Dashboard/ProfilePage/ProfilePage";
import ProfilePreview from "./pages/Dashboard/ProfilePage/ProfilePreview";
import CalculateLoan from "./pages/Dashboard/CalculateLoan/CalculateLoan";
import AboutCompany from "./pages/Dashboard/AboutCompany/AboutCompany";
import Ekyc from "./pages/Dashboard/Ekyc/Ekyc";
import UploadBankStatement from "./pages/Dashboard/UploadBankStatement/UploadBankStatement";
import Eligibility from "./pages/Dashboard/Eligibility/Eligibility";
import ProtectedRoute from "./components/ProtectedRoute";
import PanUpload from "./pages/Dashboard/PanUpload/PanUpload";
import DocumentUpload from "./pages/Dashboard/DocumentUpload/DocumentUpload";
import ThankYou from "./pages/Dashboard/ThankYou/ThankYou";
import UploadSalaryslip from "./pages/Dashboard/UploadSalaryslip/UploadSalaryslip";
import UploadUtilitybill from "./pages/Dashboard/UploadUtilitybill/UploadUtilitybill";
import LeadPreview from "./pages/Dashboard/ProfilePage/LeadPreview";
import LoanHistory from "./pages/LoanHistory/LoanHistory";
import LoanDetail from "./pages/LoanHistory/LoanDetail"
import RepayPage from "./pages/Dashboard/ProfilePage/RepayPage";
import BankDetail from "./pages/Dashboard/BankDetail/BankDetail";
import RepayThankyou from "./pages/RepayThanku";
import InstantLoan from "./pages/InstantLoan";
import VideoThankyou from "./pages/VideoThanku";
const showmessage = async (message) => {
  try {
    const x = document.getElementById("snackbar");
    if (x) {
      x.className = "show"; // Add "show" class to make the snackbar visible
      x.innerText = message;
      setTimeout(function () {
        x.className = x.className.replace("show", ""); // Hide snackbar after 3 seconds
      }, 3000);
    }
  } catch (error) {
    console.log(error);
  }
};


function Router() {
  return (
    <>
      <ScrollToTop />
      <Routes>
      <Route path="/thankyouvkyc" element={<VideoThankyou/>}/>
      <Route path="/repayloan" element={<RepayLoan showmessage={showmessage} />} />
      <Route path="/thanku" element={<RepayThankyou/>}/>
      <Route path="instant-loan" element={<InstantLoan />} />

        <Route path="/" element={<Layout />}>
          
          <Route path="apply-now" element={<ApplyForLoan showmessage={showmessage} />} />
         
      


        </Route>

        <Route path="/my-dashboard" element={<LoanLayout />}>
          <Route path="/my-dashboard" element={<DashBoard showmessage={showmessage} />} />
          <Route path="/my-dashboard/pan-details" element={<PanDetails />} />
          <Route path="/my-dashboard/captur-address" element={<CapturAddress />} />
          <Route path="/my-dashboard/captur-personal-information" element={<CapturPersonalInformation />} />
          <Route path="/my-dashboard/captur-income-details" element={<CapturIncomeDetails />} />
          <Route path="/my-dashboard/upload-picture" element={<UploadPicture />} />
          <Route path="/my-dashboard/user-profile" element={<ProfilePage />} />
          <Route path="/my-dashboard/profile-preview" element={<ProfilePreview />} />
          <Route path="/my-dashboard/calculate-loan" element={<CalculateLoan />} />
          <Route path="/my-dashboard/about-your-company" element={<AboutCompany />}/>
          <Route path="/my-dashboard/bank-detail" element={<BankDetail />} />
          <Route path="/my-dashboard/kyc" element={<Ekyc />} />
          <Route path="/my-dashboard/bank-upload" element={<UploadBankStatement />} />
          <Route path="/my-dashboard/pan-upload" element={<PanUpload />} />
          <Route path="/my-dashboard/adhar-upload" element={<DocumentUpload />} />
          <Route path="/my-dashboard/congratulations" element={<ThankYou />} />
          <Route path="/my-dashboard/upload-salaryslip" element={<UploadSalaryslip />} />
          <Route path="/my-dashboard/upload-utilitybill" element={<UploadUtilitybill />} />
          <Route path="/my-dashboard/lead-preview" element={<LeadPreview />} />
          <Route path="/my-dashboard/leads" element={<LoanHistory />} />
          <Route path="/my-dashboard/repayment" element={<RepayPage />} />
          <Route path="/my-dashboard/details" element={<LoanDetail />} />
          {/* <Route path="/my-dashboard/eligibility" element={<Eligibility />} /> */}
       
          <Route
            path="/my-dashboard/eligibility"
            element={
              <ProtectedRoute
                redirectTo="/my-dashboard"
                element={<Eligibility />}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default Router;



