// import React, { useContext, useEffect, useState } from "react";
// import c1icon from "../../../images/c1.png";
// import c2icon from "../../../images/c2.png";
// import c4icon from "../../../images/c4.png";
// import { useNavigate } from "react-router-dom";
// import { getStorage, isEmpty } from "../../../Utils/common";
// import ContextDashboard from "../../../Context/ContextDashboard";
// import { DashboardWrapper } from "../style";
// import ProgressBar from "../../../components/ProgressBar/ProgressBar";
// import DashboardCard2 from "../DashboardCard2";

// const cardList = [

//   {
//     heading: "Loan Calculator",
//     desc: "Choose the loan amount and tenure.",
//     img: c1icon,
//     link: "/my-dashboard/calculate-loan",
//   },
//   {
//     heading: "Employment Information",
//     desc: "Share about your work status.",
//     img: c2icon,
//     link: "/my-dashboard/about-your-company",
//   },
//   {
//     heading: "Upload Your Documents",
//     desc: "Share your documents to verify your details",
//     img: c4icon,
//     link: "/my-dashboard/adhar-upload",
//   },
//   {
//     heading: "Banking Details",
//     desc: "Share your banking details",
//     img: c2icon,
//     link: "/my-dashboard/bank-detail",
//   },
  
// ];

// function Eligibility() {
//   const [cards, setCards] = useState(cardList);
//   const [progressBar, setProgressBar] = useState(getStorage("step_percent"));
//   const [stepComplate, setStepComplate] = useState(false);
//   const { setps,currentEvent } = useContext(ContextDashboard);
//   const navigate = useNavigate();
//   const [activeCardIndex, setActiveCardIndex] = useState(0);

//   const redirect = (data) => {
//     navigate(data.link);
//   };

//   // useEffect(() => {
//   //   if (!isEmpty(setps)) {
//   //     checkStep(setps);
//   //   }
//   // }, [setps]);

//   // function checkStep(data) {
//   //   setProgressBar(data?.step_complete_percent);

//   //   // Check completion status of each step
//   //   const isFirstCardComplete = data?.documents_uploads === "DONE";
//   //   const isSecondCardComplete = data?.company_info === "DONE";

//   //   // Activate next card based on completion
//   //   if (isFirstCardComplete && activeCardIndex === 0) {
//   //     setActiveCardIndex(1);
//   //   } 
//   //   if (isFirstCardComplete && isSecondCardComplete && activeCardIndex === 1) {
//   //     setActiveCardIndex(2);
//   //   }
//   // }

//   // useEffect(() => {
//   //   setCards((prevCards) =>
//   //     prevCards.map((card, index) => ({
//   //       ...card,
//   //       complate: (index === 0 && setps?.documents_uploads === "DONE") ||
//   //                 (index === 1 && setps?.company_info === "DONE") ||
//   //                 (index === 2 && setps?.loan_cal === "DONE"),
//   //     }))
//   //   );
//   // }, [setps]);

//   useEffect(()=>{
//     const steps=currentEvent(getStorage("next_step"));
//     setActiveCardIndex(steps);
    
//   },[]);

//   return (
//     <DashboardWrapper>
//       <ProgressBar value={`${progressBar}%`} />
//       <div className="carde">
//         {cards.map((value, index) => {
//           const isActive = index === activeCardIndex;
//           return (
//             <DashboardCard2
//               number={index + 1}
//               heading={value.heading}
//               desc={value.desc}
//               img={value.img}
//               complate={value.complate}
//               disable={!isActive}
//               key={index}
//               onClick={() => isActive && redirect(value)} // Only redirect if the card is active
//             />
//           );
//         })}
//         <div className="hideMD"></div>
//       </div>
//     </DashboardWrapper>
//   );
// }

// export default Eligibility;



import React, { useContext, useEffect, useState } from "react";
import c1icon from "../../../images/c1.png";
import c2icon from "../../../images/c2.png";
import c4icon from "../../../images/c4.png";
import { useNavigate } from "react-router-dom";
import { getStorage, setStorage } from "../../../Utils/common";
import ContextDashboard from "../../../Context/ContextDashboard";
import { DashboardWrapper } from "../style";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import DashboardCard2 from "../DashboardCard2";
import { getDashboardData } from "../../../Utils/api";
import { Helmet } from "react-helmet";

const cardList = [
  {
        heading: "Loan Calculator",
        desc: "Choose the loan amount and tenure.",
        img: c1icon,
        link: "/my-dashboard/calculate-loan",
      },
      {
        heading: "Employment Information",
        desc: "Share about your work status.",
        img: c2icon,
        link: "/my-dashboard/about-your-company",
      },
      {
        heading: "Upload Your Documents",
        desc: "Share your documents to verify your details",
        img: c4icon,
        link: "/my-dashboard/adhar-upload",
      },
      {
        heading: "Banking Details",
        desc: "Share your banking details",
        img: c2icon,
        link: "/my-dashboard/bank-detail",
      },
      
    ];


function Eligibility() {
  const [cards, setCards] = useState(cardList);
  const [stepComplate, setStepComplate] = useState(false);
  const [showSteps, setShowSteps] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [progressBar,setProgressBar] = useState(0)
  const {setps} = useContext(ContextDashboard);

  const navigate = useNavigate();
  const {currentEvent} = useContext(ContextDashboard);

  const redirect = (data) =>{
    console.log(data);
    navigate(data.link)
  }

  // const showSteps_ = ()=>{

  //  setToggle(!toggle)
  // }
  

  // useEffect(()=>{
  //   console.log("setps",setps)
  //   if(!isEmpty(setps)){
  //     checkStep(setps);
  //   }
   
  // },[setps]);

// function checkStep(data){
 
//       setProgressBar(data?.step_complete_percent);
//       const steps = (data?.step_stage - 1) ;
//       if(data?.step_complete_percent === 100){
//         setToggle(false)
//       }
//       setShowSteps(steps);
      
     
  
//   }


// useEffect(()=>{
//   setCards([
//     {
//       heading: "Loan Calculator",
//       desc: "Choose the loan amount and tenure.",
//       img: c1icon,
//       complate:setps?.loan_cal === "DONE",
//       link: "/my-dashboard/calculate-loan",
//     },
//     {
//       heading: "Employment Information",
//       desc: "Share about your work status.",
//       img: c2icon,
//       complate:setps?.company_info === "DONE",
//       link: "/my-dashboard/about-your-company",
//     },
//     {
//       heading: "Upload Your Documents",
//       desc: "Share your documents to verify your details",
//       img: c4icon,
//       complate:setps?.documents_uploads === "DONE",
//       link: "/my-dashboard/adhar-upload",
//     }
//   ])
// },[setps])
useEffect(() => {

  const generateClickId = () => {
    const now = new Date();
    return `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;
  };
  // Trigger Facebook Pixel on component mount
  const sendFacebookPixelEvent = async () => {
    const img = new Image();

    img.src =  `https://www.facebook.com/tr?id=856557663119830&ev=PageView&noscript=1`
     
  };

   // Trigger Whistle Pixel of valueleaf
    const sendWhistlePixel = () => {
      const clickId = generateClickId();
      const img = new Image();
      img.src = `https://intexm.trackier.co/acquisition?click_id=${clickId}&security_token=19f7db61ebddbf3d4201&goal_value=Thankyou_page`;
    };
  sendWhistlePixel();

  // Call the pixel function
  sendFacebookPixelEvent();
}, []); // Empty dependency array to ensure it runs only once when the component mounts


useEffect(() => {
  const params = {
    profile_id: getStorage("cust_profile_id") || "",
  };

  getDashboardData(params).then(resp => {

    if (resp?.data?.Status === 1) {
      
      const dashboardData = resp?.data || {};
      setStorage('dashboardData', dashboardData);
      setStorage('percent',dashboardData?.Data?.application_filled_percent)
      setProgressBar(dashboardData?.Data?.application_filled_percent);
      setStorage('next_step',dashboardData?.Data?.next_event_name);
      setShowSteps(currentEvent(dashboardData?.Data?.next_event_name)-9)
    }
  });
}, []);

useEffect(()=>{
  const steps=currentEvent(getStorage("next_step"));
  setShowSteps(steps);
  
},[]);

  return (
    <>
    <Helmet>
      {/* profuse pixel */}

    <script>
          {`

  gtag('event', 'conversion', {'send_to': 'AW-16725590798/7pynCKSj3NkZEI6Gsac-'});

          `}
        </script>

        {/* end profuse pixel */}

       {/* value leaf pixel  */}
       <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-16745229237/A4TuCN6El_IZELXX37A-'});
          `}
        </script>
        {/* end valueleaf pixel */}
                <script>
                  {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '856557663119830');
                fbq('track', 'Lead');

              `}
                </script>
                {/* Intexm pixel */}
               <script>
                  {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '577487571707196');
fbq('track', 'Lead');


`}
</script>

<noscript>
  {`<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=577487571707196&ev=Lead&noscript=1"
/>
`}</noscript>



 {/* End Intexm pixel */}




{/* <!-- Criteo Add to Cart Tag --> */}
<script type="text/javascript">
{`
  window.criteo_q = window.criteo_q || [];
  var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
  window.criteo_q.push(
    { event: "setAccount", account: 117850 },
    { event: "setEmail", email: "##Email Address of User##" },
    { event: "setSiteType", type: deviceType},
    { event: "addToCart", item: [
      {id: "1", price: "1", quantity: "1" }
    ]}
  );
  `}
</script>
{/* <!-- END Criteo Add to Cart Tag --> */}
                
              </Helmet>
    <DashboardWrapper>
      <ProgressBar value={`${progressBar}%`} title="Loan Application"  >
      </ProgressBar>

     {<div className="carde">
        {cards.map((value, index) => {
          return (
           <DashboardCard2
                number={index + 1}  // Adding the card number
                heading={value.heading}
                desc={value.desc}
                img={value.img}
                complate={(index + 1) <= showSteps}
                disable={index !== showSteps}
                key={index}
                onClick={() => redirect(value)}
              />
          );
        })}
       <div className="hideMD"></div>
      </div>
      
      }
      
    </DashboardWrapper>
    </>
  );
}

export default Eligibility;