import React, { useEffect, useState } from 'react';
import { BoxWrapper } from '../../../style';
import congratulations from "../../../images/congratulations.gif";
import { useNavigate } from 'react-router-dom';
import { getStorage } from '../../../Utils/common';
import { Helmet } from "react-helmet";

function ThankYou() {
  const [progressBar, setProgressBar] = useState(getStorage('percent'));
  const [clickId1, setClickId1] = useState();

  useEffect(() => {
    const generateClickId = () => {
      const now = new Date();
      return `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;
    };
    
    const clickId2 = generateClickId(); 
    setClickId1(clickId2);

    // Function to trigger Facebook Pixel event
    const sendFacebookPixelEvent = () => {
      const img = new Image();
      img.src = `https://www.facebook.com/tr?id=856557663119830&ev=PageView&noscript=1`;
    };

    // Trigger Whistle Pixel
    const sendWhistlePixel = async (clickId) => {
      const revenue = '10.00'; // Example revenue value
      const img = new Image();
      img.src = `https://utils.follow.whistle.mobi/pixel.php?linkid=${clickId}&revenue=${revenue}&goal_name=Thankyou_page`;
    
      const img2 = new Image();
      img2.src = `https://intexm.trackier.co/acquisition?click_id=${clickId}&security_token=19f7db61ebddbf3d4201&goal_value=Application_Submit`;
    };

    sendWhistlePixel(clickId2);
    sendFacebookPixelEvent();

  }, []); 

  return (
    <div>
      <Helmet>
  

        {/* Intexm Pixel */}
        <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-11162196353/asVaCMrNi_YZEIGzxsop'});
          `}
        </script>

      
        <script>
          {`
            gtag('event', 'conversion', {
              'send_to': 'AW-16559892329/e17lCOSB8cwZEOnOr9g9',
              'value': 1.0,
              'currency': 'INR'
            });
          `}
        </script>

     

        {/* Facebook CompleteRegistration Pixel */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '684971346895514');
            fbq('track', 'CompleteRegistration');
          `}
        </script>

        {/* Criteo Purchase Tag */}
        <script type="text/javascript">
          {`
            window.criteo_q = window.criteo_q || [];
            var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
            window.criteo_q.push(
              { event: "setAccount", account: 117850 },
              { event: "setEmail", email: "##Email Address of User##" },
              { event: "setSiteType", type: deviceType },
              { event: "trackTransaction",
                id: "${clickId1}",
                item: [{ id: "1", price: "1", quantity: "1" }]
              }
            );
          `}
        </script>
      </Helmet>

      <iframe
        src={`https://affiliates.adsplay.in/post_install_custom_event1.php?pgmid=3421268&&eventname=disbursal`}
        style={{ display: 'none', width: '1px', height: '1px', border: 'none' }}
        allowTransparency
      />
      
      <img height="1" width="1" style={{ display: "none" }}
        src="https://www.facebook.com/tr?id=546967787828750&ev=PageView&noscript=1"
      />

      <BoxWrapper className="w100 gray">
        <div
          className="formmainBox flex"
          style={{
            backgroundImage: `url(${congratulations})`,
            backgroundSize: 'cover',
            height: '615px',
            width: '100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="left">
            {/* Any other content you want to show */}
          </div>
        </div>
      </BoxWrapper>
    </div>
  );
}

export default ThankYou;
