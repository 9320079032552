import React, { useState, useEffect } from "react";
import "../css/Header.css";
import logo from "../images/logo.png"; // Default logo
import stickyLogo from "../images/logo_black.png"; // Sticky header logo

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className={`header-laravel ${isSticky ? "sticky" : ""}`}>
      {/* Menu Icon (Left Side) */}
      <div
        className={`menu-toggle-laravel ${menuOpen ? "hide-menu" : ""}`}
        onClick={toggleMenu}
      >
        {!menuOpen && (
          <div className="menu-icon-laravel">
            <span className="icon-bar-laravel"></span>
            <span className="icon-bar-laravel"></span>
            <span className="icon-bar-laravel"></span>
          </div>
        )}
      </div>

      {/* Logo (Centered) */}
      <div className="logo">
        <a href="/">
          <img src={isSticky ? stickyLogo : logo} alt="Logo" />
        </a>
      </div>

      {/* Close Icon (Right Side) */}
      {menuOpen && (
        <div className="close-toggle-laravel" onClick={toggleMenu}>
          <span className="close-icon-laravel">✕</span>
        </div>
      )}

      {/* Navigation Menu */}
      <nav className={`nav-laravel ${menuOpen ? "open" : ""}`}>
        <ul>
          <li><a href="/" onClick={closeMenu}>HOME</a></li>
          <li><a href="/about-us" onClick={closeMenu}>ABOUT US</a></li>
          <li><a href="/our-services" onClick={closeMenu}>OUR SERVICES</a></li>
          <li><a href="/contact-us" onClick={closeMenu}>CONTACT US</a></li>
        </ul>
        <a href="/apply-now">
          <button className="btn-apply-now">APPLY NOW</button>
        </a>
        <a href="/repayloan">
          <button className="btn-pay-now">PAY NOW</button>
        </a>
      </nav>
    </header>
  );
};

export default Header;
