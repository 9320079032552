import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalWrapper } from "./style";
import ContextDashboard from '../../Context/ContextDashboard';
import { getStorage } from "../../Utils/common";

// Function to trigger the Google Ads conversion tracking
const gtag_report_conversion = (url) => {
  const callback = () => {
    if (typeof(url) !== 'undefined') {
      window.location = url; // Redirect after conversion tracking
    }
  };

  // Track the conversion with Google Ads
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11162196353/1Z1rCITc26saEIGzxsop',
    'value': 1.0,
    'currency': 'INR',
    'event_callback': callback,
  });

  return false; // Prevent default action
};

const Modal = ({ onClose, msg, state }) => {
  const navigate = useNavigate();
  const { handleEvent } = useContext(ContextDashboard);

  // Function to handle the "Process" button click
  const handleProcessClick = () => {
    // Trigger Google Ads conversion tracking
    gtag_report_conversion(); // Call the conversion tracking function

    // Proceed with your usual logic (e.g., navigation to eligibility page)
    navigate("/my-dashboard/eligibility");
  };

  // Function to handle the "OK" button click
  const handleOkClick = () => {
    onClose(); // Close the modal
  };

  return (
    <ModalWrapper>
      <div className="modal-box">
        <h2>{msg}</h2>
        <div className="modal-buttons">
          {state === 1 ? (
            <button onClick={handleProcessClick}>Process</button>
          ) : (
            <>
              <button onClick={handleOkClick}>OK</button>
            </>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
